import React from "react"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { graphql } from "gatsby"

import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"
import Navbar from "../../components/navbar/navbar"
import NavbarMobile from "../../components/navbar/navbarMobile"
import BannerWithForm from "../../components/openday/bannerWithForm"
import Footer from "../../components/footer/footer"
import FooterMobile from "../../components/footer/footerMobile"
import BoxWithText from "../../components/openday/boxWithText"
import ImageWithText from "../../components/openday/imageWithText"
import BannerWithTextAndFormMobile from "../../components/openday/bannerWithFormMobile"
import BoxWithTextMobile from "../../components/openday/boxWithTextMobile"
import ImageWithTextMobile from "../../components/openday/imageWithTextMobile"

const IndexPage = ({ data }) => {
  return (
    <>
      <SEO titleTemplate="%s" title="Carreira Dental Clinic" />
      <Layout home mobile={useBreakpoint().mobile}>
        {useBreakpoint().mobile ? (
          <>
            <NavbarMobile />
            <BannerWithTextAndFormMobile openday="ortodontia" />
            <BoxWithTextMobile />
            <ImageWithTextMobile />
            <FooterMobile data={data.globalJson.footer} />
          </>
        ) : (
          <>
            <Navbar />
            <BannerWithForm openday="ortodontia" />
            <BoxWithText />
            <ImageWithText />
            <Footer data={data.globalJson.footer} />
          </>
        )}
      </Layout>
    </>
  )
}

export default IndexPage

export const Json = graphql`
  query openorto {
    globalJson {
      footer {
        newsletter {
          subscreva
          btnTxt
        }
        aveiro {
          title
          morada
          contacto
        }
        agueda {
          title
          morada
          contacto
        }
        porto {
          title
          morada
          contacto
        }
        viseu {
          title
          morada
          contacto
        }
        oliveira {
          title
          morada
          contacto
        }
        email
        schedule
        bg {
          publicURL
        }
      }
    }
  }
`
